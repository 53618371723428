import React from 'react';
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";

import { ThemeContext } from '../../context/theme-context';

import Layout from '../../components/layout';
import Footer from '../../components/footer';
import Title from '../../components/title';
import BannerImage from '../../components/bannerImage';
import ContactBlock from '../../components/contactBlock';
import NextCase from '../../components/nextCase';
import IntroBlock from '../../components/introBlock';
import BannerResult from '../../components/bannerResult';

const CasesPage = ({ location }) => {

    return (

        <Layout bg="white" location={location}>

            <Helmet>
                <script src={withPrefix('wrap.js')} type="text/javascript" />
                <title>ATUO - Cases - Gunner</title>
            </Helmet>

            <div className="container mx-auto">

                <Title
                    title="Gunner"
                    titleColor="#90805B"
                    description="O tiro certeiro para uma marca transformar paixão e precisão em estilo de vida"
                    smallDescription="[brio, integridade, astúcia]"
                />

                <BannerImage src={`/gunner/gunner_content_one.jpg`} title="Gunner" />

                <IntroBlock
                    logo={`/gunner/gunner_logo.svg`}
                    alt="Firstoeat"
                    description="Criar uma marca que representasse o propósito do clube exaltando seu potencial e diferenciais."
                    list={['Estratégia & Inovacão', 'Pesquisa & Inteligência', 'Design de Experiência do Consumidor', 'Cultura, Conteúdo & Social']} />

                <div className="grid grid-cols-1 md:grid-cols-2 items-center">
                    <div className="w-full md:w-4/5">
                        <p className="text-2xl md:text-4xl mb-4">Inteligência, Força, Grupo, Autodefesa e Tática, valores para formar uma marca.</p>
                    </div>

                    <div className="mt-6 md:mt-0">
                        <img src={`/gunner/gunner_content_two.jpg`} alt="Cervogia" />
                    </div>
                </div>


                <div className="w-full md:w-3/5 mx-auto text-center mt-20 md:mt-32 mb-10">
                    <p className="text-2xl md:text-4xl mb-4">Utilizamos o cano raiado de uma arma para formar a imagem de um falcão, animal que reúne os valores essenciais da marca, sempre atacando com precisão, foco, inteligência e letalidade.</p>
                    <p>O ambiente do clube de tiro foi planejado para proporcionar uma completa sensação de imersão. Desde a recepção até a loja de produtos táticos, cafeteria, vestiário e as 8 raias de tiro, tudo foi planejado para uma experiência segura e confortável. Voltada para o público classe A, a marca tem o objetivo de tornar-se uma grife.</p>
                </div>

                <div>
                    <img src={`/gunner/gunner_content_three.jpg`} alt="Cervogia" />
                </div>

                <div className="flex flex-col md:flex-row">
                    <div>
                        <img src={`/gunner/gunner_content_four.jpg`} alt="Cervogia" />
                    </div>

                    <div className="flex flex-row md:flex-col">
                        <div><img src={`/gunner/gunner_content_five.jpg`} alt="Cervogia" /></div>
                        <div><img src={`/gunner/gunner_content_six.jpg`} alt="Cervogia" /></div>
                    </div>
                </div>


                <div className="grid grid-cols-1 md:grid-cols-2 my-20 md:my-32">
                    <div className="w-full md:w-4/5">
                        <p className="text-2xl md:text-4xl mb-4">Site que oferece uma experiência imersiva completa.</p>
                        <p>Concebido digital first, o site Gunner apresenta de forma clara e objetiva cada atração que o visitante encontrará em uma visita ao clube de tiro, além de oferecer cursos e consultoria para esclarecer dúvidas.</p>
                    </div>

                    <div className="text-center mt-10 md:mt-0">
                        <img src={`/gunner/gunner_content_seven.png`} alt="Cervogia" className="w-3/4 inline" />
                    </div>
                </div>
            </div>

            <BannerResult
                bg="#90805B"
                list={['Posicionamento claro', 'Marca com propósito', 'Experiência imersiva', 'Melhor experiência de compra']} />

            <NextCase
                link="/cases/firstoeat"
                title="Firstoeat"
                description="Um banho de loja para uma marca querida se tornar tendência"
                image={`/nextCase/next_case_trelo.jpg`} />

            <ThemeContext.Consumer>
                {cx => (
                    <ContactBlock
                        description="Gostou dos nossos cases? Tem muito mais de onde veio! Clique no botão ao lado e entre em contato conosco. Vamos dar o primeiro passo para sua marca ir além."
                        weather={cx?.cookies?.weather}
                    />
                )}
            </ThemeContext.Consumer>

            <Footer />

        </Layout>
    );
};

export default CasesPage;
